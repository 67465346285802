import * as React from "react";
import {useState} from "react";
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {useFenixContext} from "../../helpers/context";

export default function AdvisorSlider(props: any) {
  const {state, dispatch} = useFenixContext();
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  const [pagesEl, setPagesEl] = useState<HTMLElement | null>(null)

  return (
    <>
      <div className="block-wrapper">
        <h2 className="advisor-book__title text-white text-center">{props.advisorTitle}</h2>
        <p className="text-white text-18 text-center pt-3">We help you arrange a personal farewell according to your
          wishes.</p>
        <Swiper
          className={'advisor-book'}
          modules={[Navigation, Pagination]}
          navigation={{prevEl, nextEl}}
          slidesPerView={"auto"}
          keyboard={true}
          allowTouchMove={true}
          mousewheel={true}
          simulateTouch={true}
          observer={true}
          observeParents={true}
          pagination={{
            clickable: true,
            el: pagesEl
          }}
          loop={true}
          breakpoints={{
            319: {
              slidesPerView: 1,
              keyboard: true,
              allowTouchMove: true,
              mousewheel: false,
              simulateTouch: true,
            },
            719: {
              slidesPerView: 2,
              spaceBetween: 15,
              keyboard: true,
              allowTouchMove: true,
              mousewheel: false,
              simulateTouch: true,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
              keyboard: true,
              allowTouchMove: true,
              mousewheel: true,
              simulateTouch: true,
            },
          }}
        >
          {props.advisorList.map((item: any, index: any) => {
            return (
              <SwiperSlide key={index} className={'advisor-list__card'}>
                <a className="gtm-strongintent">
                  <div className="advisor-list__heading">
                    <div className="cta-card__person text-center">
                      <GatsbyImage
                        className="advisor-list__img"
                        image={getImage(item.advisorCity?.photo2x?.localFile?.childImageSharp)}
                        alt={"Funeral Advisor - Fenix Funeral Directors"} loading="lazy"
                      />
                    </div>
                    <div className="advisor-list__title">
                      <h3 className="text-white">{item.advisorCity?.fullname}</h3>
                    </div>
                  </div>
                  <button
                    type="button" className="btn btn-transparent btn-shevron btn-shevron--white"
                    onClick={() => dispatch({type: "modalBooking"})}
                    data-toggle="modal"
                    data-target="#bookMeetingForm"
                    title="Book consultation">
                    <span>Book a meeting</span>
                  </button>
                </a>
              </SwiperSlide>
            )
          })
          }
          <div ref={(node) => setNextEl(node)}
               className="swiper-button-next swiper-button-next-advisor swiper-button-advisor d-block"></div>
          <div ref={(node) => setPrevEl(node)}
               className="swiper-button-prev swiper-button-prev-advisor swiper-button-advisor d-block"></div>
        </Swiper>
      </div>
    </>
  );
}

const stylesInline: { [key: string]: React.CSSProperties } = {
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  }
};