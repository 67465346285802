import * as React from "react";
import {useEffect, useRef, useState} from 'react';

function AccordionItem(props: any) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (props.isOpen) {
      const contentEl = contentRef.current as HTMLDivElement;

      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [props.isOpen]);

  return (
    <div className={`accordion-item pt-3 pb-3 ${props.isOpen ? 'active' : ''}`}>
      <div className="accordion-item-title">
        <button
          className="accordion-item-btn w-100 text-deepblue display-flex align-items-center bg-white text-left pt-3 pb-3 pr-5 pl-3 pr-lg-5 pl-lg-5"
          onClick={props.btnOnClick}>{props.data?.question}</button>
      </div>
      <div className="accordion-item-container pr-3 pl-3 pr-lg-5 pl-lg-5" style={{height}}>
        <div ref={contentRef} className="accordion-item-content text-wrap">{props.data?.answer}</div>
      </div>
    </div>
  );
}

export default AccordionItem;